import React, { useEffect, useState } from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import { useLocation } from "react-router-dom";
import fall1 from "../../../../assets/img/grass7.jpg"
import img1 from "../../../../assets/img/grass6.jpg";

import one from "../../../../assets/img/one.svg";
import two from "../../../../assets/img/two.svg";
import three from "../../../../assets/img/three.svg";
import four from "../../../../assets/img/four.svg";
import "../../styleAllServices.css";

export default function CommercialLandscapeMaintenance() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);

    const newTitle = "Residential Landscape Maintenance | BUR-HAN Garden & Lawn Care";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "Enhance the beauty and functionality of your outdoor space with expert residential landscape maintenance services from BUR-HAN. Our comprehensive solutions ensure a thriving and picturesque environment year-round, backed by a commitment to personalized service and eco-friendly practices.";
    setPageDescription(newDescription);

    const newKeywords = [
      "residential landscape maintenance, pesticide-free landscaping, BUR-HAN Garden & Lawn Care, Vancouver lawn care, sustainable landscaping, organic lawn care, landscape services, property maintenance, Vancouver BC landscaping, residential lawn care, landscape enhancement, eco-friendly landscaping, garden maintenance, outdoor space maintenance",
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);

  return (
    <div className="wrapper">
      <Header />
      <div className="serviceItemsHeader landscapeHeader">
        <h1 data-aos="zoom-in">Residential Landscape Maintenance</h1>
      </div>
      <div className="serviceItemsMain">
      <h2 data-aos="fade-down">
          "Enhance the beauty and function of your outdoor space with our expert
          lawn care services."
        </h2>
        <hr />
      
        {/* -------------- serviceImages SECTION -------------*/}
        <section className="section-container">
              <div className="image-container px-5"   data-aos="fade-right">
                <img src={fall1}  style={{height: "43vw"}} alt="grubs" />
              </div>
              <div className="text-container px-5"  data-aos="fade-up">
                <h3 className="headingImageText text-uppercase">
                Residential Landscape Maintenance
                </h3>
                <p style={{ lineHeight: "1.5" }}>
                Since 1995, BUR-HAN Garden & Lawn Care has been a trusted name in professional landscape maintenance, providing reliable, year-round services designed to keep residential properties at their best throughout the seasons. With nearly 30 years of experience, we are dedicated to the ongoing care and enhancement of your yard, using 100% pesticide-free methods to ensure it remains healthy, attractive, and functional all year round.
                Our focus on consistent, year-round maintenance means that every aspect of your landscape receives the attention it needs, from routine lawn care and seasonal cleanups to tailored treatments that adapt with each season's demands. Homeowners trust BUR-HAN for our commitment to quality, reliability, and environmentally responsible service, making us the go-to choice for maintaining vibrant outdoor spaces.
                Whether you're looking to preserve your landscape's beauty or make enhancements, our team works with you to bring your vision to life, ensuring lasting results that reflect your style and values. BUR-HAN is here to provide comprehensive, pesticide-free care for your yard—all year, every year.
                </p>
              </div>
            </section>
        {/* -------------- BANNER SECTION -------------*/}

        <div className="banner-section">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <h3 className="heading" data-aos="fade-right">
                  "Empowering Progress, Igniting <span> Results!" </span>
                </h3>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                data-aos="fade-left"
              >
                <p className="text">
                  "Revitalize your outdoor space with our expert landscaping
                  maintenance services, ensuring a thriving and picturesque
                  environment year-round."
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* -------------- about SECTION -------------*/}
       
        <section className="section-container">
          <div className="text-container px-5" data-aos="fade-up">
            <h3 className="headingImageText text-uppercase">
            Why Choose BUR-HAN Garden & Lawn Care?
            </h3>
            <ul>
              <li>
                <strong>Experienced Professionals:</strong> With nearly three decades in the business, our team has the expertise to handle all aspects of landscape maintenance.
              </li>
              <li>
                {" "}
                <strong>Year-Round, Complete Service Plans:</strong> We offer comprehensive maintenance plans that ensure your lawn, shrubs, plants, trees, hedges, and garden beds are well-cared for throughout the year, covering all your landscaping needs.

              </li>
              <li>
                {" "}
                <strong>Real-Time Reporting:</strong> Stay informed with real-time reports detailing the work done on each visit, providing transparency and peace of mind.

              </li>
              <li>
                <strong>Consistent Personnel:</strong> Enjoy the reliability of having the same dedicated professionals caring for your property on each visit, fostering familiarity and trust.

              </li>
              <li>
                <strong>Seasonal Planting Options:</strong> Enhance your garden's beauty with our seasonal planting services, adding color and vibrancy throughout the year.
              </li>
              <li><strong>Health and Safety:</strong> We're committed to keeping your outdoor spaces safe and healthy for your family and the environment. With local laws preventing the use of cosmetic pesticides, our pesticide-free approach ensures that your property stays in harmony with these regulations, all while protecting what matters most—your loved ones and nature."
              </li>
              <li>
                <strong>Landscape Enhancement Specialist:</strong>  Access expert advice and suggestions from our landscape enhancement specialist to elevate the aesthetics and functionality of your outdoor space.

              </li>
              <li>
                <strong>Dedicated Maintenance Supervisor:</strong> A dedicated landscape maintenance supervisor will visit your property monthly to ensure the highest quality of work from our crews.

              </li>
              
            </ul>
            <p style={{paddingLeft:"1vw"}}>At BUR-HAN Garden & Lawn Care, we take pride in offering personalized, high-quality landscape maintenance services that cater to the unique needs of each client. Let us help you create and maintain a beautiful, thriving outdoor environment that you can enjoy year-round.</p>
          </div>
          <div className="image-container px-5" data-aos="fade-left">
            <img src={img1} alt="landscape maintenance" className="landscapeHeightImg" />
          </div>
      
        </section>

        <section className="ourValues ourvnew" style={{backgroundColor:"rgba(0,0,0,0.1)"}}>
         
        <h2 className="section-header my-1 mb-3"   data-aos="zoom-in">
        How It Works: Getting Started with Your Residential Landscape Maintenance Plan
        <h6 className="text-center mt-2" style={{ fontFamily:"din-light",margin: "0 4vw"}}>At BUR-HAN, we make the process simple and stress-free, ensuring your landscape gets the expert care it deserves.
        </h6>
        </h2>
        <div className="row mb-1">
          <div
            className="col-lg-3 col-sm-6 "
            data-aos="fade-right"
          >
            <div className="content" style={{marginLeft:".3vw" }}>
              <img src={one} style={{ width: "4.3rem"}} alt="number 1" className="mb-3" />
              <h3>Request a Quote</h3>
              <p style={{width:"80%"}} > Use our "Get a Quote" button
              to answer a few quick questions about
              your property, or simply give us a call at 
              
            604-983-2687. We'll take care of the rest!</p>
            </div>
          </div>
          <div
            className="col-lg-3 col-sm-6"
            data-aos="zoom-in"
          >
            <div className="content ">
              <img src={two} style={{ width: "4.3rem" }} alt="number 2" className="mb-3" />
              <h3>Site Visit</h3>
              <p style={{width:"80%"}} > Our professional estimators will contact you to schedule a visit, in person or independently, ensuring your property receives personalized attention.
              </p>
            </div>
          </div>
          <div
            className="col-lg-3 col-sm-6 "
            data-aos="fade-left"
          >
            <div className="content">
              <img src={three} style={{ width: "4.3rem" }} alt="number 3" className="mb-3" />
              <h3>Proposal Creation</h3>
              <p style={{width:"80%"}} > After carefully measuring and assessing your property, our estimator will prepare a detailed proposal tailored to your specific needs.
              </p>
            </div>
          </div>
          <div
            className="col-lg-3 col-sm-6 "
            data-aos-delay="300"
            data-aos="fade-right"
          >
            <div className="content">
              <img src={four} style={{ width: "4.3rem" }} alt="number 4" className="mb-3" />
        <h3>Review and Approve</h3>
              <p style={{width:"80%"}} > The proposal will be sent to you for your review. If everything meets your expectations, a simple phone call or email is all it takes to start your service.
              </p>
            </div>
          </div>
        </div>
        
      </section>       
 <div className="secondBanner">
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-md-6 ">
                <h3 className="sndheading">Get Started <span>Today!</span></h3>
                <p>
                  Fill out our Get a Quote page, and let our expert landscaping
                  team bring your vision to life with a personalized estimate
                  tailored to your unique preferences and needs.
                </p>
              </div>
              <div className="col-md-6 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Get A Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
