import React, { useEffect, useState} from "react";
import { useLocation } from "react-router-dom";

import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import fall1 from "../../../../assets/img/fall1.jpeg";
import fall2 from "../../../../assets/img/fall2.jpeg";
import fall3 from "../../../../assets/img/fall3.jpeg";

import "../../styleAllServices.css";


export default function LeafCleanup() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);
    const newTitle =
      "Leaf Cleanup Services";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
    "BUR-HAN Garden & Lawn Care offers professional leaf cleanup services in Vancouver, North Vancouver, West Vancouver, and Burnaby. Ensure your lawn stays healthy and your property looks its best this fall with expert leaf removal, raking, and disposal. Contact us today to schedule your service." 
      setPageDescription(newDescription);

    const newKeywords = [
      "Leaf cleanup Vancouver, Leaf removal services North Vancouver,Fall lawn care West Vancouver,Vancouver leaf raking services,Residential leaf cleanup Burnaby,Leaf cleanup company Vancouver BC,Fall yard clean-up West Vancouver"
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);


  return (
    <div className="wrapper">
      <Header />

      <div className="serviceItemsHeader">
        <h1 data-aos="zoom-in">Leaf Cleanup</h1>
      </div>
      <div className="serviceItemsMain">
      <h2 data-aos="fade-down">
          "Enhance the beauty and function of your outdoor space with our expert
          leaf cleanup services."
        </h2>
      
        <hr style={{ marginBottom: "2vw" }} />

        <div className="container-grubgone">
          <div className="row">
            {/* -------------- about SECTION -------------*/}
            <section className="section-container">
              <div className="image-container px-5" data-aos="fade-right">
                <img src={fall1}  alt="grubs" />
              </div>
              <div className="text-container px-5" data-aos="fade-up">
                <h3 className="headingImageText text-uppercase">
                Leaf Cleanup Services 
                </h3>
                <p style={{ lineHeight: "1.5" }}>
                As the autumn season approaches, fallen leaves can quickly accumulate on your property, affecting your lawn's health and your landscape's overall appearance. At BUR-HAN Garden & Lawn Care, we provide professional leaf cleanup services in Vancouver, North Vancouver, West Vancouver, and Burnaby, ensuring your lawn stays healthy and your property looks its best all season.

                </p>
              </div>
            </section>
            <section className="hero1" data-aos="fade-up"></section>

            <section className="section-container">
              <div className="text-container px-5" data-aos="fade-up">
                <h3 className="headingImageText text-uppercase">
                Why Leaf Cleanup is important:
                </h3>
                <p>Fallen leaves, while beautiful, can suffocate your lawn and inhibit grass growth. If left unattended, they can lead to:
                </p>
                
                <ul>
                <li>
                    <strong>Lawn Damage: </strong>  : A thick layer of leaves can block sunlight, leading to dead spots and thinning grass.
                  </li>
                  <li>
                    <strong>Mold and pests: </strong>  Wet leaves create an ideal environment for mold growth and can attract unwanted pests.



                  </li>
                  <li>
                    <strong>Blocked drains and gutters:</strong> Leaves can clog your drainage systems, causing water backup and potential property damage.


                  </li>
</ul>
<h3 className="headingImageText text-uppercase">
Our Comprehensive Leaf Cleanup Services:
                </h3>
                <p>Our team of experts will ensure that your property is leaf-free, tidy, and ready for the cooler months. Our services include:

                </p>
             
<ul>
                  <li><strong>Leaf raking and blowing:</strong> We use professional-grade equipment to collect and remove leaves efficiently.
</li>
                 
                  <li>
                  <strong>Leaf removal and disposal:</strong> We'll remove all leaf waste, leaving your property spotless.

                  </li>
                  <li>
                <strong> Lawn health check: </strong> While performing the cleanup, we assess your lawn to recommend other services, such as aeration, lime application, or moss control, to prepare your lawn for winter.


                  </li>
                </ul>
          
              </div>
              <div className="image-container px-5" data-aos="fade-left">
                <img src={fall2} alt="grubs" />
              </div>
            </section>

            {/* -------------- END OF ABOUT SECTION -------------*/}
            <section className="section-container gb">
              <div className="image-container px-5" data-aos="fade-right">
                <img src={fall3} alt="grubs" />
              </div>
              <div className="text-container px-5" data-aos="fade-up">
                <h3 className="headingImageText text-uppercase">
                Why Choose BUR-HAN Garden & Lawn Care?</h3>
                <p>As a trusted landscaping company in the Pacific Northwest, we understand the unique challenges that local properties face during the fall. Our team is equipped to handle leaf cleanup on both residential and commercial properties, and we pride ourselves on our reliability, attention to detail, and customer satisfaction.
                </p>
                <p>We serve the following areas:
                </p>
                <ul>
                 
                  <li>
                    <strong>North Vancouver </strong>
                  </li>
                  <li>
                    <strong>West Vancouver
                    </strong> 

                  </li>
                  <li>
                    <strong>Vancouver </strong>
                  </li>
                  <li>
                    <strong>Burnaby
                    </strong> 

                  </li>
</ul>



              </div>
            </section>

            
            {/* -------------- END OF BANNER -------------*/}
            <div className="secondBanner">
              <div className="container" data-aos="fade-down">
                <div className="row">
                  <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-8">
                    <h3 className="grubBannerTitle">
                    Get Ready for Fall with Our Professional Leaf Cleanup Services

                    </h3>
                    <p className="grubBannerText">
                    Don't let the falling leaves take over your lawn. Let BUR-HAN Garden & Lawn Care handle the hard work for you! <span style={{color:"var(--yellow-color)"}}>Contact us today</span> to schedule your leaf cleanup and ensure your property stays beautiful throughout the fall season.                 </p>   </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-4">
                    <div className="call-to-action text-right">
                      <a href="/contact-us" className="get-a-quote grubBannerButton">
                        Contact Us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
