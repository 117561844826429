import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../styleAllProjects.css";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photosOnetimeCleanups } from "../../../script";
import beesFlying from "../../../assets/img/load.gif";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function OneTimeCleanups() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);

    const newTitle = "One-time Cleanups | BUR-HAN Garden & Lawn Care";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "Explore our upcoming one-time cleanup projects and learn how we can transform your outdoor space with our comprehensive cleanup services. Stay tuned for expert one-time cleanup services designed to revitalize your property.";
    setPageDescription(newDescription);

    const newKeywords = [
      "one-time cleanups, outdoor cleanup, property cleanup, landscape cleanup, yard cleanup, outdoor maintenance, cleanup services",
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div className="wrapper">
      <Header />

      <div className="projectsGalleryHeader">
        <h1 data-aos="zoom-in"> One-Time Cleanups</h1>
      </div>
      <div className="projectsGalleryMain" data-aos="fade-up">
        <h2>COMING SOON!</h2>
        <a href="/our-projects" className="to-top">
          Back to projects
        </a>

        <img
          src={beesFlying}
          style={{ margin: "auto", display: "block" }}
          className="beeGif"
          alt="honeycomb"
        />

        {/* <h2>   <a href="">  
Click here to learn more about our services       
       </a> </h2>    
        <hr />
        <a href="/our-projects" className="to-top">Back to projects</a>

        <div className="gallery">
         <Gallery photos={photosOneTimeCleanups} onClick={openLightbox}    
/>
      <ModalGateway >
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel 
 currentIndex={currentImage}
 views ={photosOneTimeCleanup.map(x => ({
    ...x,
    srcset: x.srcSet,
    caption: x.title,
    
 }))}
/>

       

          </Modal>
        ) : null}
      </ModalGateway> 
      

       

      </div> */}
        <Footer />
      </div>
    </div>
  );
}
