import React, { useEffect, useState } from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import { useLocation } from "react-router-dom";
import img from "../../../../assets/img/grass3.jpg";
import img2 from "../../../../assets/img/image3a.jpg";
import img3 from "../../../../assets/img/strata3.jpg";
import img4 from "../../../../assets/img/grass6.jpg";

import "../../styleAllServices.css";

export default function CommercialLandscapeMaintenance() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);

    const newTitle =
      "Commercial Landscape Maintenance | BUR-HAN Garden & Lawn Care";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "Enhance the beauty and functionality of your outdoor space with expert commercial landscape maintenance services from BUR-HAN. Our comprehensive solutions ensure a thriving and picturesque environment year-round, backed by a commitment to personalized service and eco-friendly practices.";
    setPageDescription(newDescription);

    const newKeywords = [
      "commercial landscape maintenance, pesticide-free landscaping, BUR-HAN Garden & Lawn Care, Vancouver lawn care, sustainable landscaping, organic lawn care, landscape services, property maintenance, Vancouver BC landscaping, commercial lawn care, landscape enhancement, eco-friendly landscaping, garden maintenance, outdoor space maintenance",
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);

  return (
    <div className="wrapper">
      <Header />
      <div className="serviceItemsHeader landscapeHeader">
        <h1 data-aos="zoom-in">Commercial Landscape Maintenance</h1>
      </div>
      <div className="serviceItemsMain">
        <h2 data-aos="fade-down">
          "Enhance the beauty and function of your outdoor space with our expert
          lawn care services."
        </h2>
        <hr />

        {/* -------------- serviceImages SECTION -------------*/}
        <section className="section-container">
          <div className="image-container px-5" data-aos="fade-right">
            <img src={img} alt="grubs" />
          </div>
          <div className="text-container px-5" data-aos="fade-up">
            <h3 className="headingImageText text-uppercase">
              Commercial Landscape Maintenance
            </h3>
            <p>
              {/* At BUR-HAN Garden & Lawn Care, we take pride in being the only landscaping company to guarantee 100% pesticide-free maintenance services for commercial properties. Our commitment to sustainable practices sets us apart, as we provide a unique combination of eco-friendly care and meticulous attention to detail. We use proven traditional techniques that promote the health and beauty of your property while ensuring it remains free of harmful chemicals. Whether you manage office spaces, retail centers, or multi-use developments, our expert team delivers reliable and high-quality maintenance that aligns with the highest industry standards. With our services, your commercial real estate will not only look its best but also reflect your values by promoting a safer, cleaner environment for employees, clients, and visitors alike. */}
              At BUR-HAN Garden & Lawn Care, we proudly offer Vancouver's only
              guaranteed pesticide-free commercial landscape maintenance. As the
              largest company in Greater Vancouver dedicated to this
              eco-friendly approach, we bring unmatched care and expertise to
              every property, using traditional, time-tested techniques that
              maintain your landscapes to the highest standards—without relying
              on harmful chemicals. Our meticulous approach ensures that your
              commercial real estate, whether office spaces, retail complexes,
              schools, warehouses, or mixed-use developments, looks its best
              year-round while supporting a healthier, cleaner environment.
              Choose BUR-HAN for sustainable landscape care that enhances your
              property's appearance and upholds your commitment to safety and
              environmental responsibility.
            </p>
          </div>
        </section>
        {/* -------------- BANNER SECTION -------------*/}

        <div className="banner-section">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <h3 className="heading" data-aos="fade-right">
                  "Empowering Progress, Igniting <span> Results!" </span>
                </h3>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                data-aos="fade-left"
              >
                <p className="text">
                  "Revitalize your outdoor space with our expert landscaping
                  maintenance services, ensuring a thriving and picturesque
                  environment year-round."
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* -------------- about SECTION -------------*/}
        <section className="section-container">
          <div className="image-container px-5" data-aos="fade-right">
            <img
              src={img3}
              alt="landscape maintenance"
             className="commercialHeightImg"
            />

            {/* <img src={img1} alt="landscape maintenance" style={{ width:"60%"}} /> */}
          </div>
          <div className="text-container px-4" data-aos="fade-up">
            <span className="caption d-block mb-2 font-weight-bold">
              Outstanding Services
            </span>
            <h3 className="headingImageText text-uppercase ">
              Benefits of Pesticide-Free Maintenance
            </h3>
            <ul>
              <li>
                <strong>Healthier Environment:</strong> By avoiding pesticides,
                we create a safer and healthier environment for your tenants and
                their employees. This leads to improved well-being and can even
                boost productivity and satisfaction among those who work and
                visit your property.
              </li>

              <li>
                <strong>Environmental Protection:</strong> Pesticide-free
                maintenance helps protect local wildlife, including beneficial
                insects, birds, and other animals. It also prevents harmful
                chemicals from leaching into the soil and waterways, promoting a
                healthier ecosystem.
              </li>
              <li>
                <strong>Sustainable Practices:</strong> Our use of traditional
                landscaping techniques supports sustainable practices that are
                better for the environment. This includes methods like manual
                weeding, mulching, and the use of organic fertilizers, which
                help maintain soil health and biodiversity.
              </li>

              <li>
                <strong>Enhanced Property Value:</strong> Properties maintained
                without pesticides are often viewed more favourably by tenants
                and investors who value sustainability and environmental
                responsibility. This can enhance the reputation and value of
                your commercial real estate.
              </li>
              <li>
                <strong>Compliance and Safety:</strong> Many regions have strict
                regulations regarding pesticide use. Our pesticide-free approach
                ensures your property remains compliant with all local laws and
                regulations, reducing the risk of legal issues and potential
                fines.
              </li>
              <li>
                <strong>Community Appeal:</strong> Demonstrating a commitment to
                environmentally friendly practices can improve your standing in
                the community. It shows that your business prioritizes the
                health and well-being of both people and the planet.
              </li>
            </ul>
          </div>
        </section>
        <section className="section-container">
          <div className="text-container px-5" data-aos="fade-up">
            <h3 className="headingImageText text-uppercase">
              Why Choose BUR-HAN Garden & Lawn Care?
            </h3>
            <ul>
              <li>
                <strong>Experienced Professionals:</strong> With nearly three
                decades of experience, our team has the expertise to handle all
                aspects of commercial landscape maintenance, ensuring top-notch
                service every time.
              </li>
              <li>
                {" "}
                <strong>Year-Round, Complete Service Plans:</strong>  We provide comprehensive maintenance plans that cover all your landscaping needs throughout the year, ensuring your lawns, shrubs, plants, trees, hedges, and garden beds are well-cared for.

              </li>
              <li>
                {" "}
                <strong>Real-Time Reporting:</strong> Stay informed with
                real-time reports detailing the work done on each visit,
                providing transparency and peace of mind.
              </li>
              <li>
                <strong>Consistent Personnel:</strong> Enjoy the reliability of
                having the same dedicated professionals caring for your property
                on each visit, fostering familiarity and trust.
              </li>
              <li>
                <strong>Seasonal Planting Options:</strong> Enhance your
                garden's beauty with our seasonal planting services, adding
                color and vibrancy throughout the year.
              </li>
              <li>
                <strong>Landscape Enhancement Specialist:</strong> Access expert
                advice and suggestions from our landscape enhancement specialist
                to elevate the aesthetics and functionality of your outdoor
                space.
              </li>
              <li>
                <strong>Dedicated Maintenance Supervisor:</strong> A dedicated
                landscape maintenance supervisor will visit your property
                monthly to ensure the highest quality of work from our crews.
              </li>
            </ul>
            <p  style={{paddingLeft:"1vw"}}>
              At BUR-HAN Garden & Lawn Care, we take pride in offering
              personalized, high-quality landscape maintenance services tailored
              to the unique needs of each client. Let us help you create and
              maintain a beautiful, thriving outdoor environment that you can
              enjoy year-round. For more information or to schedule a
              consultation, contact us today!
            </p>
          </div>
          <div className="image-container px-5" data-aos="fade-left">
            <img
              src={img2}
              alt="landscape maintenance"
              className="strataHeightImg"
            />
          </div>
        </section>
        {/* -------------- END OF BANNER -------------*/}
        <div className="secondBanner" style={{ marginTop: "0" }}>
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-md-6 ">
                <h3 className="sndheading">
                  Get Started <span>Today!</span>
                </h3>
                <p>
                  Fill out our Get a Quote page, and let our expert landscaping
                  team bring your vision to life with a personalized estimate
                  tailored to your unique preferences and needs.
                </p>
              </div>
              <div className="col-md-6 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Get A Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
