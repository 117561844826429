import React, { useEffect, useState} from "react";
import { useLocation } from "react-router-dom";

import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import fall1 from "../../../../assets/img/fall1.jpeg";
import fall2 from "../../../../assets/img/fall2.jpeg";
import fall3 from "../../../../assets/img/fall3.jpeg";

import "../../styleAllServices.css";


export default function GardenCleanup() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);
    const newTitle =
      "";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
    "Get your property ready for winter with our Garden Cleanup services in Vancouver, British Columbia. BUR-HAN Garden & Lawn Care offers expert leaf removal, lawn care, and garden preparation to keep your outdoor space pristine through the colder months."  
      setPageDescription(newDescription);

    const newKeywords = [
      "Garden cleanup Vancouver, Leaf removal services North Vancouver, Fall lawn care West Vancouver, Vancouver leaf raking services, Residential leaf cleanup Burnaby, Leaf cleanup company Vancouver BC, Fall yard clean-up West Vancouver"
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);


  return (
    <div className="wrapper">
      <Header />

      <div className="serviceItemsHeader">
        <h1 data-aos="zoom-in">Garden Cleanup</h1>
      </div>
      <div className="serviceItemsMain">
      <h2 data-aos="fade-down">
          "Enhance the beauty and function of your outdoor space with our expert
          garden cleanup services."
        </h2>
        <div className="row justify-content-md-center mb-5 ">
          <div
            className="col-lg-3 md-3 col-sm-6 col-xs-12"
            data-aos="fade-right"
          >
            <a href="/services/garden-cleanups/garden-cleanup/bamboo-cleanup">
            <div className="servicesCard transfNone">
                <div className="cover ">
                  <h3>Bamboo Cleanup </h3>
                </div>
              </div>
            </a>
          </div>
          <div
            className="col-lg-3 md-3 col-sm-6 col-xs-12"
            data-aos="fade-down"
          >
            <a href="/services/garden-cleanups/garden-cleanup/pruning-shearing">
            <div className="servicesCard transfNone">
                <div className="cover ">
                  <h3>Pruning/Shearing
                  </h3>
                </div>
              </div>
            </a>
          </div>
      <div
            className="col-lg-3 md-3 col-sm-6 col-xs-12"
            data-aos="fade-down"
          >
            <a href="/services/garden-cleanups/garden-cleanup/blackberry-removal">
            <div className="servicesCard transfNone">
                <div className="cover ">
                  <h3>Blackberry Removal</h3>
                </div>
              </div>
            </a>
          </div>
      </div>
        <hr style={{ marginBottom: "2vw" }} />

        <div className="container-grubgone">
          <div className="row">
            {/* -------------- about SECTION -------------*/}
          

            
            {/* -------------- END OF BANNER -------------*/}
            <div className="secondBanner">
              <div className="container" data-aos="fade-down">
                <div className="row">
                  <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-8">
                    <h3 className="grubBannerTitle">
                    Get Ready for Fall with Our Professional Leaf Cleanup Services

                    </h3>
                    <p className="grubBannerText">
                    Don't let the falling leaves take over your lawn. Let BUR-HAN Garden & Lawn Care handle the hard work for you! <span style={{color:"var(--yellow-color)"}}>CONTACT US TODAY</span> to schedule your leaf cleanup and ensure your property stays beautiful throughout the fall season.                 </p>   </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-4">
                    <div className="call-to-action text-right">
                      <a href="/contact-us" className="get-a-quote grubBannerButton">
                        Contact Us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
