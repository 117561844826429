import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { useLocation } from "react-router-dom";
import "../styleAllBlogs.css";
import img1 from "../../../assets/img/blackberry01.jpg";
import img2 from "../../../assets/img/blackberry02.jpg";
import img3 from "../../../assets/img/blackberry03.jpg";
import img4 from "../../../assets/img/blackberry04.jpg";
import img5 from "../../../assets/img/blackberry05.jpg";
import img6 from "../../../assets/img/blog-blackberry1.jpg";
import img7 from "../../../assets/img/blog-blackberry2.jpg";
import img8 from "../../../assets/img/blog-blackberry3.jpg";
import img9 from "../../../assets/img/blog-blackberry4.jpg";

export default function BlackberryRemoval() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [pageType, setPageType] = useState("");

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);

    const newTitle =
      "The Essential Guide to Blackberry Removal in Greater Vancouver";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "Learn how to remove blackberry overgrowth effectively and sustainably in Greater Vancouver. Our guide covers each step, from cutting canes to root removal, to keep your landscape free from invasive blackberry plants without pesticides. Serving North Vancouver, Vancouver, Burnaby, Surrey, and more.";
    setPageDescription(newDescription);

    const newType = "article";
    setPageType(newType);

    const newKeywords = [
      "Blackberry removal guide, eco-friendly blackberry removal, North Vancouver blackberry control,Vancouver invasive plant removal, blackberry overgrowth, blackberry root removal, pesticide-free landscaping, blackberry control tips, sustainable landscaping Vancouver",
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update type meta tag
    document
      .querySelector('meta[property="og:type"]')
      .setAttribute("content", newType);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);

  return (
    <div className="wrapper">
      <Header />
      <div className="beforeSection"></div>
      <div className="sectionBlogpost blackberry">
        <a href="/blog" className="backPosts">
          <i className="bi bi-arrow-left"></i>
          Back
        </a>
        <article>
          <header>
            <h2>
              <div className="nth-line-1" data-aos="fade-up">
                The Essential Guide to
              </div>
              <div className="nth-line-2" data-aos="fade-up">
                Blackberry Removal in Greater Vancouver
              </div>
            </h2>
            <p className="lead" data-aos="fade-right"></p>
          </header>
          <hr />
          <section data-aos="fade-right">
            <p>
              Blackberry plants are a common sight across the Greater Vancouver
              area, from North Vancouver and West Vancouver to Burnaby, Surrey,
              and Richmond. While their berries are tempting, the plants
              themselves can quickly turn into a real issue, spreading fast and
              becoming difficult to control. If you’re dealing with blackberry
              overgrowth on your property, here’s everything you need to know
              about effectively removing these plants in an eco-friendly way.
            </p>
            <p>
              <span>
                Step-by-Step Blackberry Removal - No Pesticides Needed
              </span>
              <br />
              Removing blackberry plants thoroughly means addressing both the
              canes and the roots, and it takes some know-how to do it right.
              Here's how we handle it:
            </p>
            <img src={img1} width={"100% !important"} alt="blackberry plant" />
            <p>
              <span>Step 1: Trim Down the Canes First</span>
              <br />
              Starting with the canes makes things a lot easier. By cutting back
              the thick, thorny canes to about 6-12 inches from the ground, we
              get rid of the bulk, making it simpler to reach the root system
              without getting snagged by thorns.
              <br />
              - Tools of the Trade: Pruning shears or loppers work best for this
              step, though we might use a small saw for thicker growth. <br />-
              Proper Disposal: Blackberry plants spread easily, so we keep the
              trimmed canes contained in a pile to stop any seeds from
              scattering around the property.
            </p>
            <p>
              <span>Step 2: Expose the Root Crown and Main Roots</span>
              <br />
              Once the canes are trimmed, the next step is to dig around the
              root crown (where the canes meet the roots). This helps us get a
              good look at where the blackberry plant is rooted in the ground.
              <br />
              - Tools for Digging: A spade or digging fork works well to loosen
              the soil around the root area. <br />- Method: We start about 6-12
              inches away from the base of the plant, working carefully to avoid
              snapping any roots. This makes it easier to remove the entire root
              system in the next step.
            </p>
            <p>
              <span>Step 3: Remove the Root System</span>
              <br /> This is the most important step because blackberries can
              regrow if any root fragments are left behind. The root system of a
              blackberry plant can run deep and wide, so it’s important to dig
              it out thoroughly.
              <br />
              - Depth and Breadth: Dig at least 6-12 inches down, following any
              roots we come across. Blackberry roots spread horizontally too, so
              we make sure to cover enough ground.
              <br />- Lift Carefully: Using a spade, we gently pull the roots
              out without breaking them. Even a small leftover piece can lead to
              regrowth, so thoroughness here pays off.
            </p>
            <p>
              <span>Step 4: Clear and Inspect the Area</span>
              <br />
              Once the main root system is removed, it's worth going back over
              the area to look for any remaining root fragments. Blackberry
              plants are persistent, and even a small root piece left behind can
              mean a new plant sprouts up.
              <br />
              - Inspect Carefully: We sift through the soil to check for any
              remaining roots.
              <br />- Repeat if Necessary: In areas with dense blackberry
              growth, a second pass might be needed to ensure every last piece
              is gone.
            </p>
            <p>
              <span>Step 5: Dispose of Blackberry Remains Properly</span>
              <br />
              Proper disposal is key to making sure the removed blackberry
              plants don't take root elsewhere. We bag up all the plant material
              to prevent seeds or root pieces from spreading.
              <br />- Containment: By bagging everything up and disposing of it
              according to local green waste guidelines, we prevent future
              issues. Many areas in Greater Vancouver have specific disposal
              rules for invasive plants, so we make sure to follow those.
            </p>
            <p>
              <span>Step 6: Watch for Regrowth</span>
              <br />
              Even with careful removal, blackberry plants might still try to
              come back. Regular checks make it easier to spot any new shoots
              and remove them early.
              <br />
              - Regular Monitoring: Every few weeks, especially during the
              growing season, it's helpful to check the cleared area for new
              shoots.
              <br />- Quick Removal: If anything does reappear, we dig it out
              immediately to keep the blackberry plants from reestablishing.
            </p>
            <p>
              <span>
                Serving the Greater Vancouver Area with Eco-Friendly Blackberry
                Removal
              </span>
              <br />
              If you're in North Vancouver, West Vancouver, Vancouver, Burnaby,
              Surrey, Richmond, Coquitlam, Port Coquitlam, White Rock, or Maple
              Ridge, and need blackberry removal that's thorough and
              pesticide-free, our process can help restore your landscape. With
              our expertise, we make sure blackberry plants are removed in a way
              that's safe for the environment, without the use of any chemicals.{" "}
            </p>
            <img src={img8} width={"100% !important"} alt="blackberry plant" />
            Whether it's for a residential garden or a larger commercial
            property, this hands-on, natural approach is the most effective way
            to control blackberry growth and maintain a clean, well-kept
            landscape in the Greater Vancouver area.
          </section>
        </article>
      </div>
      <Footer />
    </div>
  );
}
