import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  servicesOption,
  selectCity,
  hearaboutusOption,
  styles,
} from "../../../script";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import grub4 from "../../../assets/img/grub-gone4.png";
import grub5 from "../../../assets/img/grub-gone5.jpg";
import grub6 from "../../../assets/img/grub-gone6.png";
import grub7 from "../../../assets/img/grub-gone7.png";
import grubcycle from "../../../assets/img/grub-gone0.png";
import flower from "../../../assets/img/flower.jpeg";
import summer from "../../../assets/img/summer.jpeg";
import fall from "../../../assets/img/fall.jpeg";
import "./style.css";

const animatedComponents = makeAnimated();

export default function ChaferBeetleControl() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);
    const newTitle =
      "European Chafer Beetle Control | BUR-HAN Garden & Lawn Care";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "Learn how to control European Chafer Beetles and protect your lawn from damage. Discover tips for identification, repair, and prevention. Contact us for expert assistance and quotes. Serving North Vancouver, West Vancouver, and the North Shore.";
    setPageDescription(newDescription);

    const newKeywords = [
      "European chafer beetle control, European chafer beetle, European Chafer, vancouver chafer beetle, chafer beetles, chafer beetle, chafer, beetle, schafer beetle, grub, bug, lawn pest, lawn care, damage control, north vancouver lawn damage , north shore chafer damage, west vancouver lawn damage, grubs, chafer beetles, chafer damage, lawn damage, merit, acelepryn, nematodes, crow damage, animal damage",
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const history = useNavigate(); // Get the history object from React Router
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailInput = form.current["email"].value;
    if (!emailRegex.test(emailInput)) {
      alert("Please enter a valid email address.");
      return;
    }

    emailjs
      .sendForm(
        process.env.REACT_APP_TEMPLATE_ID,
        process.env.REACT_APP_SERVICE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          setShowSuccessMessage(true); // Show the popup message
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };

  const handleReload = () => {
    setShowSuccessMessage(false); // Hide the popup message
    history("/"); // Navigate to the homepage
    window.location.reload(); // Reload the page
  };

  return (
    <div className="wrapper">
      <Header />

      <div className="serviceItemsHeader">
        <h1 data-aos="zoom-in">European Chafer Beetle Control</h1>
      </div>
      <div className="serviceItemsMain">
        <h2
          data-aos="fade-down"
          style={{
            fontSize: "3vw",
            color: "var(--green-color)",
            letterSpacing: ".2rem",
          }}
        >
          Grub Problems? We Have the Solution!
        </h2>
        <hr style={{ marginBottom: "2vw" }} />

        <div className="container-grubgone">
          <div className="row">
            {/* -------------- about SECTION -------------*/}
            <section className="section-container">
              <div className="image-container px-5" data-aos="fade-right">
                <img src={grub5} alt="grubs" />
              </div>
              <div className="text-container px-5" data-aos="fade-up">
                <h3 className="headingImageText text-uppercase">
                  What is The European Chafer Beetle?
                </h3>
                <p style={{ lineHeight: "1.5" }}>
                  The European Chafer Beetle originated in continental Europe
                  but can now be found in temperate climates across North
                  America. The lifecycle of the Chafer Beetle is one year, with
                  new eggs hatching in July pending weather conditions. These
                  voracious pests feed on grass roots, causing significant
                  damage to lawns. Their feeding activity creates brown patches,
                  wilting grass, and eventually bare spots in the lawn.
                  Additionally, the presence of grubs attracts predators like
                  crows, skunks and racoons, which further disrupt the turf
                  while foraging for food. If left untreated, grub infestations
                  can devastate lawns, leading to costly repairs and diminished
                  curb appeal.
                </p>
              </div>
            </section>
            <section className="hero" data-aos="fade-up"></section>

            <section className="section-container">
              <div className="text-container px-5" data-aos="fade-up">
                <h3 className="headingImageText text-uppercase">
                  Tips for identifying a grub infestation on lawns:
                </h3>
                <ul>
                  <li>
                    <strong>Inspect the Soil:</strong> Gently lift a section of
                    the turf and examine the soil underneath. Grubs typically
                    reside within the top few inches of soil, so look for small,
                    C-shaped larvae.
                  </li>
                  <li>
                    <strong>Brown Patches:</strong> Keep an eye out for
                    irregular patches of brown or dead grass in your lawn. Grub
                    feeding disrupts the root system, leading to areas of
                    weakened or dying grass. Grubs will continue to work their way outward from the center of damage and can persist up to one meter away from any obviously damaged edges.

                  </li>

                  <li>
                    <strong>Animal Activity: </strong> Wildlife such as skunks,
                    raccoons, and birds are attracted to lawns infested with
                    grubs. Look for signs of digging or foraging, as these
                    animals may be seeking out grubs as a food source.
                  </li>
                  <li>
                    <strong>Lawn Damage:</strong> Grub feeding can cause the
                    turf to detach from the soil, making it easy to roll up like
                    a carpet. Check for areas where the grass lifts easily or
                    where it appears to have been pulled back.
                  </li>
                  <li>
                    <strong>Check During Active Periods: </strong> Grubs are
                    most active in late summer and early fall when they are
                    feeding and growing. Be especially vigilant for signs of
                    infestation during these times.
                  </li>
                </ul>
              </div>
              <div className="image-container px-5" data-aos="fade-left">
                <img src={grub4} alt="grubs" />
              </div>
            </section>

            {/* -------------- END OF ABOUT SECTION -------------*/}
            <section className="section-container gb">
              <div className="image-container px-5" data-aos="fade-right">
                <img src={grub6} alt="grubs" />
              </div>
              <div className="text-container px-5" data-aos="fade-up">
                <h3 className="headingImageText text-uppercase">
                  Tips on Repairing Chafer Beetle Damage during grass growing
                  seasons:
                </h3>
                <ul>
                  <li>
                    <strong>Rake Away Old Debris:</strong> This will expose any
                    existing grubs and make it easier for wildlife to forage for
                    them.
                  </li>
                  <li>
                    <strong>Assess Damage:</strong> Come mid-late March (or
                    whenever temperatures begin to warm up) assess the extent of
                    the damage to your lawn and consider either repairing with
                    soil/seed or for larger areas or more extensive damage - new
                    sod.
                  </li>

                  <li>
                    <strong>
                      Keep Up with Healthy Lawn Care Maintenance Practices:
                    </strong>{" "}
                    The best form of defense against the Chafer Beetle is to
                    keep up with regular, healthy lawn care practices such as
                    regular lawn cutting, watering, consistent fertilization,
                    etc. The stronger and healthier you can get the root system
                    of your lawn, the less vulnerable it will be to future
                    Chafer Beetle Attacks.
                  </li>
                  <li>
                    <strong>Do Grub Gone Applications Each Season:</strong> As
                    the lifecycle of the Chafer Beetle is only 1 year, it is
                    important to keep doing Grub Gone applications each season
                    to target new grubs/beetles.
                  </li>
                </ul>
              </div>
            </section>

            <section className="section-container bgrubcycle">
              <div className="text-container px-5 grubText " data-aos="fade-up">
                <h3 className="headingImageText text-uppercase">
                  The European Chafer Beetle lifecycle:
                </h3>
                <p>
                The European Chafer Beetle has a lifecycle of one year. Eggs hatch in summer and grubs begin to feed on turfgrass roots. Come winter, they pupate underground, emerging as beetles next spring to begin the cycle anew. With mild climates like those in North & West Vancouver, chafer beetle grubs can feed on your turfgrass from mid-August through to April, causing damage to your lawn.

                </p>
              </div>
              <div className="image-container grubImg" data-aos="fade-right">
                <img src={grubcycle} alt="grub cycle" />
              </div>
            </section>

            <section
              id="timesOfTheYearSection"
              className="timesOfTheYearSection"
            >
              <div className="section-container" data-aos="fade-up">
                <div className="section-header">
                  <h2 data-aos="fade-down">
                    Times Of Year You Can Apply Grub Gone!:
                  </h2>
                </div>
                <div className="row mb-5 ">
                  <div
                    className="col-33 circlexyear"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div className="post-item ">
                      <div className="post-img">
                        <img src={flower} className="img-fluid" alt="" />
                      </div>
                      <div className="post-content">
                        <h3 className="post-title text-align-center">Spring</h3>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-33 circlexyear"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div className="post-item ">
                      <div className="post-img">
                        <img src={summer} className="img-fluid" alt="" />
                      </div>
                      <div className="post-content">
                        <h3 className="post-title text-align-center">Summer</h3>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-33 circlexyear "
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div className="post-item ">
                      <div className="post-img">
                        <img src={fall} className="img-fluid" alt="" />
                      </div>
                      <div className="post-content">
                        <h3 className="post-title text-align-center">Fall</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className=" text-center">
                    <div className="post-description">
                      <p className="post-title" data-aos="fade-up">
                        {" "}
                        <strong>SPRING: </strong> You have evidence of some
                        activity in your lawn or you see it in your immediate
                        neighbours. The colony may not be large enough in your
                        lawn yet to show damage. Also encourage your neighbour
                        to apply at this time to limit the spread from their
                        lawns.
                      </p>
                      <p className="post-title" data-aos="fade-up">
                        {" "}
                        <strong>SUMMER:</strong> You are doubling down on your
                        spring action or you did not do it in the spring and
                        your neighbours have grub activity. Generally speaking
                        new colony's won't have a large effect on the lawn at
                        this time but are very vulnerable to grub gone in their
                        earliest instars.{" "}
                      </p>
                      <p className="post-title" data-aos="fade-up">
                        <strong>FALL:</strong> You are doing your second
                        application due to known activity and to arrest any
                        activity seen in the fall in your lawn. Applying at this
                        time will limit the colony size over the greatest length
                        of time that it will be feeding in your lawn. A colony
                        left unchecked at this time of year can damage a whole
                        lawn rather than a portion of the lawn. It is often the
                        first application for people that have never had chafer
                        damage before, and is the most significant for those
                        that this is true for. Reducing the colony size now will
                        limit any damage.{" "}
                      </p>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="row cl px-5">
              <div
                className="col-xl-5 col-lg-5 col-md-12 col-sm-12 xs-12 grubProduct "
                data-aos="fade-right"
              >
                <img
                  src={grub7}
                  className="img-shadow fl grubProductImg"
                  alt=""
                />
              </div>
              <div
                className="col-xl-7 col-lg-7 col-md-11 col-sm-11 xs-10 mb-4 grubProductText  "
                data-aos="fade-left"
              >
                <h3 style={{ textTransform: "uppercase", paddingTop: "1.5vw" }}>
                  Product information
                </h3>
                <p
                  className="heading-colored-text"
                  style={{ letterSpacing: "0" }}
                >
                  Grub gone is the first Bacillus thuringiensis galleriae (Btg)
                  soil bacterium that effectively controls turf grubs at a level
                  equivalent to chemical standards [banned by pesticide bylaws]
                  without the nasty side effects or off-target damage to
                  beneficial insects (such as bees and other pollinators).
                  Unlike Nematodes, Grub Gone is effective against 1st, 2nd &
                  3rd instar pests and therefore can be used as a curative
                  treatment as well as a preventative one. Being an organic
                  product, it will not have a 100% guarantee in completely
                  killing all the grubs in your lawn but the effectiveness rate
                  when combined with healthy lawn care maintenance is closer to
                  90% (while Nematodes was only 60%). The product itself is
                  light brown in colour, finely ground, and only requires one
                  deep watering within a 3 day window of being applied (in order
                  to move the product deeper into the level of the soil where
                  grubs can feed). Once applied to your lawn and watered in,
                  Grub Gone will remain in the soil for up to 3 months.
                </p>
              </div>
            </div>

            {/* -------------- END OF BANNER -------------*/}
            <div className="secondBanner">
              <div className="container" data-aos="fade-down">
                <div className="row">
                  <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-8">
                    <h3 className="grubBannerTitle">
                      #1 Recommendation to avoid future chafer beetle damage:
                    </h3>
                    <p className="grubBannerText">
                      Keep up with regular healthy lawn care and maintenance,
                      regular lawn cutting, deep core aeration, fertilization,
                      watering, etc. & DO A GRUB GONE APPLICATION EACH SEASON.
                    </p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-4">
                    <div className="call-to-action text-right">
                      <a href="/contact-us" className="get-a-quote grubBannerButton">
                        Get A Quote
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
