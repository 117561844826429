import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useLocation } from "react-router-dom";
import "./style.css";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  servicesOption,
  selectCity,
  hearaboutusOption,
  styles,
} from "../../script";

const animatedComponents = makeAnimated();

export default function ContactUs() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);

    const newTitle = "Contact Us | BUR-HAN Garden & Lawn Care";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "Get in touch with BUR-HAN Garden and Lawn Care for all your lawn and landscape needs. Contact us for inquiries, estimates, and professional services including lawn care, landscape design, maintenance plans, and more.";
    setPageDescription(newDescription);

    const newKeywords = [
      "contact us, BUR-HAN Garden and Lawn Care, lawn care, landscape design, maintenance plans, lawn maintenance, landscape services, North Vancouver, BC, Canada, garden care, lawn care services",
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription, setKeywords]);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const history = useNavigate(); // Get the history object from React Router
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailInput = form.current["email"].value;
    if (!emailRegex.test(emailInput)) {
      alert("Please enter a valid email address.");
      return;
    }

    emailjs
      .sendForm(
        process.env.REACT_APP_TEMPLATE_ID,
        process.env.REACT_APP_SERVICE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          setShowSuccessMessage(true); // Show the popup message
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };

  const handleReload = () => {
    setShowSuccessMessage(false); // Hide the popup message
    history("/"); // Navigate to the homepage
    window.location.reload(); // Reload the page
  };

  return (
    <div className="wrapper">
      <Header />
      <div className="contactUsHeader">
        <h1>Contact Us</h1>
      </div>
      <div className="contactUsMain" data-aos="fade-up">
        <h2 data-aos="fade-down">
          Your next lawn or landscape project is just a few clicks away!
        </h2>
        <hr />
        <div className="contact-form-container">
          <div className="contact-us">
            <div className="contact-header">
              <h3 data-aos="fade-right">
                &#9135;&#9135;&#9135;&#9135;&nbsp;&nbsp;CONTACT
              </h3>
            </div>
            <div className="social-bar">
              <ul>
                <a href="https://www.facebook.com/bhgardenandlawncare">
                  <li>
                    {" "}
                    <i className="fab fa-facebook"> </i>
                  </li>
                </a>
                <a href="https://www.instagram.com/bhgardenandlawncare">
                  <li>
                    <i className="fab fa-instagram"></i>
                  </li>
                </a>
                <a href="https://www.youtube.com/user/readyforvideo">
                  <li>
                    <i className="fab fa-youtube"></i>
                  </li>
                </a>
                <a href="https://www.pinterest.ca/bhgardenandlawn">
                  <li>
                    <i className="fab fa-pinterest"></i>
                  </li>
                </a>
              </ul>
            </div>
          </div>
          <div className="header" data-aos="fade-up">
            <h2>CONTACT US TODAY!</h2>
            <p>
              For any inquiries or to request an estimate, feel free to reach
              out to us through our contact us page and we will be happy to
              assist you.
            </p>
          </div>
          <div className="address" data-aos="fade-up">
            <i className="fas fa-map-marker-alt"></i>
            <h4>BUR-HAN Garden and Lawn Care</h4>
            <p>
              MAILING ADDRESS: Suite #314 1641 Lonsdale Ave, North Vancouver,
              BC, V7M 2J5 <br />
              SHOP ADDRESS: 2110 Front St, North Vancouver, BC, V7H 1A3
            </p>
          </div>
          <div className="phone" data-aos="fade-up">
            <i className="fas fa-phone"></i>
            <h4>PHONE:</h4>
            <a href="tel:604-983-2687">
              <p> (604) 983-2687</p>
            </a>
          </div>
          <div className="email" data-aos="fade-up">
            <i className="fas fa-envelope"></i>
            <h4>EMAIL:</h4>
            <a href="mailto:gardenandlawncare@bur-han.ca">
              <p>gardenandlawncare@bur-han.ca</p>
            </a>
          </div>
          <div className="contact-form" data-aos="fade-left">
            <div className="col-lg-6">
              <form ref={form} onSubmit={sendEmail} className="php-email-form">
                <div className="row">
                  <div className="col-md-6 form-group ">
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      id="firstName"
                      placeholder="First Name *"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="lastName"
                      className="form-control"
                      id="lastName"
                      placeholder="Last Name *"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group ">
                    <input
                      type="text"
                      name="phone"
                      className="form-control"
                      id="phone"
                      placeholder="Phone Number *"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group ">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Email *"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group ">
                    <input
                      type="text"
                      className="form-control"
                      name="address"
                      id="address"
                      placeholder="Address *"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group ">
                    <input
                      type="text"
                      className="form-control"
                      name="postalcode"
                      id="postalcode"
                      placeholder="PostalCode *"
                      required
                    />
                  </div>
                  <div className="col-md-4 form-group mb-1">
                    <Select
                      id="city"
                      classNamePrefix="input-contact"
                      className="input-contact"
                      name="city"
                      placeholder="City *"
                      options={selectCity}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      styles={styles}
                      isSearchable={false}
                      required
                    />
                  </div>
                  <div className="col-md-4 form-group mb-1">
                    <Select
                      id="services"
                      isMulti
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      classNamePrefix="input-contact"
                      className="input-contact"
                      name="services"
                      placeholder="Services *"
                      options={servicesOption}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      styles={styles}
                      isSearchable={false}
                      required
                    />
                  </div>
                  <div className="col-md-4 form-group mb-1">
                    <Select
                      id="hearaboutus"
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      classNamePrefix="input-contact"
                      className="input-contact"
                      name="hearaboutus"
                      placeholder="How did you hear about us? *"
                      options={hearaboutusOption}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      styles={styles}
                      isSearchable={false}
                      required
                    />
                  </div>
                </div>
                <textarea
                  id="requiredservices"
                  name="requiredservices"
                  className="col-md-12 mb-5 form-group  form-control"
                  placeholder="Required Services *"
                  rows="4"
                  required
                />
                <button className=" buttonSubmit" name="submit" type="submit" id="submit-contact">
                  Get A Quote
                </button>
              </form>
              {/* Popup message */}
              {showSuccessMessage && (
                <div className="popup active">
                  <div className="popup-content">
                    <i
                      className="fa fa-check main-content__checkmark"
                      id="checkmark"
                    ></i>
                    <h2>Thank you for contacting us!</h2>
                    <h3>
                      We'll get back to you as soon as possible. <br />
                      Follow us on Social:
                    </h3>
                    <div className="social">
                      <a
                        href="https://www.facebook.com/bhgardenandlawncare"
                        className="social d-flex align-items-center"
                      >
                        <i className="bi bi-facebook "></i>
                      </a>
                      <a
                        href="https://www.instagram.com/bhgardenandlawncare"
                        className="social d-flex align-items-center"
                      >
                        <i className="bi bi-instagram"></i>
                      </a>
                      <a
                        href="https://www.youtube.com/user/readyforvideo"
                        className="social d-flex align-items-center"
                      >
                        <i className="bi bi-youtube "></i>
                      </a>
                      <a
                        href="https://www.pinterest.ca/bhgardenandlawn"
                        className="social d-flex align-items-center"
                      >
                        <i className="bi bi-pinterest "></i>
                      </a>
                    </div>
                    <button onClick={handleReload}>
                      <span>Back to the Homepage </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <section className="map_sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12 ">
                <div className="map_inner">
                  <h4>Find Us on Google Maps</h4>
                  <div className="map_bind">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2601.4161203935946!2d-123.01846222309891!3d49.30640276907365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548670f2acf0caf5%3A0xa180b9dfb101ef53!2sBUR-HAN%20Garden%20%26%20Lawn%20Care!5e0!3m2!1sen!2sca!4v1707501402244!5m2!1sen!2sca"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                      width="100%"
                      height="550"
                      frameborder="0"
                      style={{
                        border: "0",
                        allowfullscreen: "",
                        ariaHidden: "false",
                        tabindex: "0",
                      }}
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
