import React, { useEffect, useState } from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import { useLocation } from "react-router-dom";
import img1 from "../../../../assets/img/strata1.jpg";
import img2 from "../../../../assets/img/strata2.jpg";
import img3 from "../../../../assets/img/strata3.jpg";

import "../../styleAllServices.css";

export default function CommercialLandscapeMaintenance() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);

    const newTitle = "Strata Landscape Maintenance | BUR-HAN Garden & Lawn Care";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "Enhance the beauty and functionality of your outdoor space with expert strata landscape maintenance services from BUR-HAN. Our comprehensive solutions ensure a thriving and picturesque environment year-round, backed by a commitment to personalized service and eco-friendly practices.";
    setPageDescription(newDescription);

    const newKeywords = [
      "strata landscape maintenance, pesticide-free landscaping, BUR-HAN Garden & Lawn Care, Vancouver lawn care, sustainable landscaping, organic lawn care, landscape services, property maintenance, Vancouver BC landscaping, strata lawn care, landscape enhancement, eco-friendly landscaping, garden maintenance, outdoor space maintenance",
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);

  return (
    <div className="wrapper">
      <Header />
      <div className="serviceItemsHeader landscapeHeader">
        <h1 data-aos="zoom-in">Strata Landscape Maintenance</h1>
      </div>
      <div className="serviceItemsMain">
      <h2 data-aos="fade-down">
          "Enhance the beauty and function of your outdoor space with our expert
          lawn care services."
        </h2>
        <hr />
      
        {/* -------------- serviceImages SECTION -------------*/}
        <section className="section-container">
              <div className="image-container px-5" data-aos="fade-right">
                <img src={img1} alt="grubs" />
              </div>
              <div className="text-container px-5" data-aos="fade-up">
                <h3 className="headingImageText text-uppercase">
                Strata Landscape Maintenance 
                </h3>
                <p style={{ lineHeight: "1.5" }}>
               
                {/* Partner with a team you can genuinely rely on to deliver high-quality landscape services, always on time and within the financial boundaries you set, ensuring every detail aligns with your expectations. We believe in keeping you informed and involved throughout every step of the process, so you're never left wondering about the status of your property. Say goodbye to the constant stress and frustration of micromanaging an underperforming landscape service provider who falls short of delivering consistent results. Our dependable, highly skilled team offers comprehensive, full-service maintenance plans designed specifically to keep your outdoor spaces safe, healthy, and visually stunning. From lawn care to seasonal cleanups, we have you covered, ensuring your property remains beautiful and well-maintained in every season of the year. */}
                Partner with a team you can trust to deliver quality, environmentally responsible landscape services on time and within your budget, ensuring seamless, stress-free property management. We believe in consistent communication, keeping you informed every step of the way so you can have peace of mind knowing your property is in reliable hands. No more dealing with the challenges of an underperforming landscape provider—our dedicated team and comprehensive, pesticide-free maintenance plans are designed to keep your property safe, healthy, and beautiful year-round. From routine care to seasonal cleanups and proactive upkeep, we handle every detail with a focus on sustainable practices, ensuring your landscape remains vibrant, pristine, and tailored to meet your community’s needs through every season.
                </p>
              </div>
            </section>
        {/* -------------- BANNER SECTION -------------*/}

        <div className="banner-section">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <h3 className="heading" data-aos="fade-right">
                  "Empowering Progress, Igniting <span> Results!" </span>
                </h3>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                data-aos="fade-left"
              >
                <p className="text">
                  "Revitalize your outdoor space with our expert landscaping
                  maintenance services, ensuring a thriving and picturesque
                  environment year-round."
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* -------------- about SECTION -------------*/}
       
        <section className="section-container">
          <div className="text-container px-5" data-aos="fade-up">
            <h3 className="headingImageText text-uppercase">
            Why Choose BUR-HAN Garden & Lawn Care?
            </h3>
            <ul>
              <li>
                <strong>Experienced Professionals:</strong> With nearly three decades of experience, our team has the expertise to handle all aspects of landscape maintenance, ensuring top-notch service every time.
              </li>
              <li>
                {" "}
                <strong>Year-Round, Complete Service Plans:</strong> We provide comprehensive maintenance plans that cover all your landscaping needs throughout the year, ensuring your lawns, shrubs, plants, trees, hedges, and garden beds are well-cared for.
              </li>
              <li>
                {" "}
                <strong>Real-Time Reporting:</strong> Stay informed with real-time reports detailing the work done on each visit, providing transparency and peace of mind.
              </li>
              <li>
                <strong>Consistent Personnel:</strong> Enjoy the reliability of having the same dedicated professionals caring for your property on each visit, fostering familiarity and trust.
              </li>
              <li>
                <strong>Seasonal Planting Options:</strong> Enhance your garden's beauty with our seasonal planting services, adding color and vibrancy throughout the year.
              </li>
              <li>
                <strong>Landscape Enhancement Specialist:</strong> Access expert advice and suggestions from our landscape enhancement specialist to elevate the aesthetics and functionality of your outdoor space.
              </li>
              <li>
                <strong>Dedicated Maintenance Supervisor:</strong> A dedicated landscape maintenance supervisor will visit your property monthly to ensure the highest quality of work from our crews.
              </li>
              
            </ul>
            <p  style={{paddingLeft:"1vw"}}>At BUR-HAN Garden & Lawn Care, we take pride in offering personalized, high-quality landscape maintenance services tailored to the unique needs of each client. Let us help you create and maintain a beautiful, thriving outdoor environment that you can enjoy year-round.
For more information or to schedule a consultation, contact us today!
</p>
          </div>
          <div className="image-container px-5" data-aos="fade-left">
            <img src={img3} alt="landscape maintenance" className="strataHeightImg" />
          </div>
        </section>
        {/* -------------- END OF BANNER -------------*/}
        <section id="quoteLine" className="quoteLine">
          <div className="content" data-aos="fade-down">
            <h3>
            If you are a property manager or self managed strata,<br/> give us a call today and we would be<br/> happy to help you!

            </h3>
            <a href="/contact-us" className="buttonQuoteLine">
              GET A QUOTE
            </a>
          </div>
        </section>
   

        <Footer />
      </div>
    </div>
  );
}
