import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../styleAllProjects.css";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photosDesign } from "../../../script";
import beesFlying from "../../../assets/img/load.gif";

import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function Design3d() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);

    const newTitle = "3d Design Project | BUR-HAN Garden & Lawn Care";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "Discover our upcoming 3D design projects and how we bring landscapes to life through immersive 3D designs. Stay tuned for realistic visual representations of landscaping projects before any work begins.";
    setPageDescription(newDescription);

    const newKeywords = [
      "3D design, landscaping projects, immersive designs, visual representation, upcoming projects, landscaping services",
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  
  
  return (
    <div className="wrapper">
      <Header />

      <div className="projectsGalleryHeader">
        <h1 data-aos="zoom-in"> 3D Design</h1>
      </div>
      <div className="projectsGalleryMain" data-aos="fade-up">
        <h2 className="text-center"> <a href="/design-and-modelling"> 
        Click here to learn more about our services   </a>       
       
        </h2>    
        <hr />

        <a href="/our-projects" className="to-top">Back to projects</a>

        <div className="gallery">
         <Gallery photos={photosDesign} onClick={openLightbox}    
/>
      <ModalGateway >
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel 
 currentIndex={currentImage}
 views ={photosDesign.map(x => ({
    ...x,
    srcset: x.srcSet,
    caption: x.title,
    alt: x.alt,

 }))}
/>

       

          </Modal>
        ) : null}
      </ModalGateway> 
      

      </div>
        <Footer />
      </div>
    </div>
  );
}
