import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { useLocation } from "react-router-dom";
import img1 from "../../../assets/img/grass.jpeg";
import img2 from "../../../assets/img/grass3.jpg";
import "../styleAllServices.css";

export default function LandscapeMaintenance() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);

    const newTitle = "Landscape Maintenance | BUR-HAN Garden & Lawn Care";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "Enhance the beauty and functionality of your outdoor space with expert landscape maintenance services from BUR-HAN. Our comprehensive solutions ensure a thriving and picturesque environment year-round, backed by a commitment to personalized service and eco-friendly practices.";
    setPageDescription(newDescription);

    const newKeywords = [
      "landscaping, lawn care maintenance, residential lawn care, garden reclamation, irrigation management, tree and shrub care, property maintenance, sustainable landscaping, landscaping services, landscape maintenance, lawn maintenance, garden maintenance, water bag installation, irrigation management, tree staking, pest control, disease control, tree care, seasonal clean-ups, landscape enhancement, outdoor space, landscaping services, eco-friendly practices, personalized service, sustainable landscaping",
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);

  return (
    <div className="wrapper">
      <Header />
      <div className="serviceItemsHeader">
        <h1 data-aos="zoom-in">Landscape Maintenance</h1>
      </div>
      <div className="serviceItemsMain">
      <h2 data-aos="fade-down">
          "Enhance the beauty and function of your outdoor space with our expert
          landscape maintenance services."
        </h2>
          <div className="row justify-content-md-center mb-5 ldmt">
          <div
            className="col-lg-3 md-3 col-sm-4 col-xs-6"
            data-aos="fade-right"
          >
            <a href="/services/landscape-maintenance/residential-landscape-maintenance">
              <div className="servicesCard transfNone">
                <div className="cover item-g">
                  <h3>Residential Landscape Maintenance </h3>
                </div>
              </div>
            </a>
          </div>
          <div
            className="col-lg-3 md-3 col-sm-4 col-xs-6"
            data-aos="fade-down"
          >
            <a href="/services/landscape-maintenance/strata-landscape-maintenance">
              <div className="servicesCard transfNone">
                <div className="cover item-c">
                  <h3>Strata Landscape Maintenance</h3>
                </div>
              </div>
            </a>
          </div>
          <div
            className="col-lg-3 md-3 col-sm-4 col-xs-6"
            data-aos="fade-left"
          >
            <a href="/services/landscape-maintenance/commercial-landscape-maintenance">
              <div className="servicesCard transfNone">
                <div className="cover item-a">
                  <h3>Commercial Landscape Maintenance</h3>
                </div>
              </div>
            </a>
          </div>
        </div>   
        <hr />
        <h2 className="section-header text-center mb-5" data-aos="fade-right">
          Landscape Maintenance Services
        </h2>
        <div className="container mb-5 ">
          <div className="row">
            <div className="hex-cols" data-aos="zoom-in">
              <div className="hexagon-menu">
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-house"></i>
                      </span>
                      <span className="title">Lawn Maintenance</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-brands fa-pagelines"></i>
                      </span>
                      <span className="title">Garden Maintenance</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-droplet"></i>
                      </span>
                      <span className="title">
                        Water bag installation and filling
                      </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-water"></i>
                      </span>
                      <span className="title">Irrigation Management</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-tree"></i>
                      </span>
                      <span className="title">Tree staking </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a
                    className="hex-content"
                    href="/services/european-chafer-beetle-control"
                  >
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-bug"></i>
                      </span>
                      <span className="title">Pest and Disease Control </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-tree"></i>
                      </span>
                      <span className="title">Tree and Shrub care </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-regular fa-calendar-days"></i>
                      </span>
                      <span className="title">Seasonal Clean-ups </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-arrow-up-right-from-square"></i>
                      </span>
                      <span className="title">Landscape Enhancement </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* -------------- serviceImages SECTION -------------*/}

        {/* -------------- BANNER SECTION -------------*/}

        {/* <div className="banner-section">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <h3 className="heading" data-aos="fade-right">
                  "Empowering Progress, Igniting <span> Results!" </span>
                </h3>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                data-aos="fade-left"
              >
                <p className="text">
                  "Revitalize your outdoor space with our expert landscaping
                  maintenance services, ensuring a thriving and picturesque
                  environment year-round."
                </p>
              </div>
            </div>
          </div>
        </div>
       
        <section className="section-container">
          <div className="image-container px-5" data-aos="fade-right">
            <img src={img1} alt="landscape maintenance" />
          </div>
          <div className="text-container px-5" data-aos="fade-up">
            <span className="caption d-block mb-2 font-weight-bold">
              Outstanding Services
            </span>
            <h3 className="headingImageText text-uppercase ">
              Landscape Maintenance
            </h3>
            <p>
              Choosing our comprehensive landscaping maintenance service isn’t
              only a decision; it's an investment in the beauty, functionality,
              and lasting value of your property. Our unwavering commitment to
              personalized service ensures that your unique vision becomes a
              reality, with our experienced team collaborating closely with you
              throughout the process. At BUR-HAN, we prioritize open
              communication, transparency, and a client-centric focus, making
              the entire landscaping journey a seamless and enjoyable
              experience. With an emphasis on sustainability and eco-friendly
              practices, we not only enhance your property's aesthetics but also
              contribute to a healthier environment.{" "}
            </p>
          </div>
        </section>
        <section className="section-container">
          <div className="text-container px-5" data-aos="fade-up">
            <h3 className="headingImageText text-uppercase">
              Why choose BUR-HAN for your
              <br /> landscape maintenance needs?
            </h3>
            <ul>
              <li>
                <strong>Investment:</strong> Increase the value of your property
                with our high-quality aesthetic.
              </li>
              <li>
                {" "}
                <strong>Sustainable practices:</strong> With our environmentally
                friendly practices, let your landscape thrive.
              </li>
              <li>
                {" "}
                <strong>Open Communication:</strong> Get weekly emails with
                detailed notes on what was done on your properties last visit.
              </li>
              <li>
                <strong>Peace of mind:</strong> With our proactive site
                assessments, we address concerns before they become larger
                issues.
              </li>
              <li>
                <strong>Reliability:</strong> Ensure your property is serviced
                on an unchanging schedule with gardeners who get to know the
                site.
              </li>
            </ul>
          </div>
          <div className="image-container px-5" data-aos="fade-left">
            <img src={img2} alt="lanscape maintenance" />
          </div>
        </section>  */}
        {/* -------------- END OF BANNER -------------*/}
        <div className="secondBanner">
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-md-6 ">
                <h3>Ready to elevate your outdoor experience?</h3>
                <p>
                  Fill out our Get a Quote page, and let our expert landscaping
                  team bring your vision to life with a personalized estimate
                  tailored to your unique preferences and needs.
                </p>
              </div>
              <div className="col-md-6 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Get A Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
