import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../styleAllBlogs.css";
import img from "../../../assets/img/blog-paving-stone.png";

export default function PavingStone() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [pageType, setPageType] = useState("");

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);

    const newTitle =
      "Paving Stone Quality and Value Blog ";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "Learn about quality and value of paving stone with this comprehensive guide by BUR-HAN Garden & Lawn Care.";
    setPageDescription(newDescription);

    const newType = "article";
    setPageType(newType);

    const newKeywords = [
      "paving stone,landscaping, outdoor renovation",
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update type meta tag
    document
      .querySelector('meta[property="og:type"]')
      .setAttribute("content", newType);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);

  return (
    <div className="wrapper">
      <Header />
      <div className="sectionBlogpost pavingstones">
        <a href="/blog" className="backPosts">
          <i className="bi bi-arrow-left"></i>
          Back
        </a>
        <article>
          <header>
            <h2>
              <div className="nth-line-1" data-aos="fade-up">
                PAVING STONE
              </div>
              <div className="nth-line-2" data-aos="fade-up">
                 QUALITY AND VALUE
              </div>
            </h2>
           
          </header>
          <hr />

          <section className="post" data-aos="fade-right">
            <p>
              <span> Quality: </span>
              When everyone has access to the same pavers, what makes the
              difference in quality? What are the less-than-obvious factors that
              play into the quality of your investment? Pavers can be of
              different grades and finishes, all of which will play to the
              durability, longevity and appearance of the surface. However, did
              you know that if the base material is not installed correctly,
              your installation can fail even with the correct pavers? We have
              all heard that a firm foundation is the start of every good thing.
              The base material depth, use of geotextiles, and the type of base
              material must be chosen with regard to the end use of the paved
              surface and the environment in which it is installed. Removal of
              the subsoil and installation of the base material is a costly
              procedure, you want to know that the base is the correct depth for
              your particular application and not just hope that it is. While
              old methods of base installation still exist and can be relied
              upon advancements in geotechnical engineering and specifically in
              geotextiles can enhance previous building techniques. Use of a
              geotextile in the base material can help to lock and hold those
              materials to prevent their movement. Do you know if your base
              needs to be permeable or nonpermeable by requirement or local
              by-law? The company you select should be employing trained and
              certified professionals for the design and installation. They will
              be aware of specific requirements and best practices of the
              industry as well as any by-law requirements. A good list of
              customer testimonials and people you can contact is a great way to
              gain insight into others' experiences. Edging and retaining
              materials are as important as the base material. Again the correct
              choice of edging and how it is installed depends on the type of
              use. If your edging moves the pavers will migrate. Migration of
              the pavers will result in a failure of the surface. Finally, a
              paver used for a driveway can be used in a walkway but not
              necessarily the other way around. Your paver's dimensions must be
              chosen for the particular application, whether it is pedestrian,
              vehicular, or both. Ensure the quality of your project by choosing
              a trusted company.
              BUR-HAN Garden & Lawn Care can help with any of your<a href="/services/hardscape-installs-maintenance" style={{color: "var(--yellow-color)",textDecoration: "none"}}> hardscape installation</a> needs. Contact us today for a free estimate!
            </p>
            <p>
              <span>Value: </span>
              Ultimately, the market consensus is that a well-designed and
              installed hardscape provides additional value to your home;
              whether that is 5% or 15%( and all debated), you will only
              realize that when you sell your home. Until then, all the value is
              in the enhancement it adds to your personal life. Your true value
              will come in the form of pride of ownership, as well as the
              physical benefits and mental well-being of being out in nature in
              your extended living space.
            </p>
            <img alt="paving stone" src={img} className="imgblog" />
          </section>
        </article>
        <div className="secondBanner">
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-md-6">
                <h3>Ready to elevate your outdoor experience?</h3>
                <p>
                  Fill out our contact page, and let our expert landscaping
                  team bring your vision to life with a personalized estimate
                  tailored to your unique preferences and needs.
                </p>
              </div>
              <div className="col-md-6 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
