import React, { useEffect, useState} from "react";
import { useLocation } from "react-router-dom";


import Header from "../../../../../components/Header";
import Footer from "../../../../../components/Footer";
import fall1 from "../../../../../assets/img/fall1.jpeg";
import fall2 from "../../../../../assets/img/fall2.jpeg";
import fall3 from "../../../../../assets/img/fall3.jpeg";

import "../../../styleAllServices.css";


export default function PruningShearing() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);
    const newTitle =
      "";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
    ""  
      setPageDescription(newDescription);

    const newKeywords = [
      ""
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);


  return (
    <div className="wrapper">
      <Header />

      <div className="serviceItemsHeader">
        <h1 data-aos="zoom-in">Pruning/Shearing</h1>
      </div>
      <div className="serviceItemsMain">
      <h2 data-aos="fade-down">
          "Enhance the beauty and function of your outdoor space with our expert
          pruning/shearing services."
        </h2>
        
        <hr style={{ marginBottom: "2vw" }} />

     </div>
     </div>
  );
}
